<template>
  <section style="margin: 0 15px">
    <div
      class="happyNewsApproval"
      style="overflow: hidden; position: relative; background-color: #fff"
    >
      <el-row
        :gutter="24"
        style="
          padding: 0 15px 15px;
          background-color: #fff;
          padding-top: 20px;
          margin: 0;
        "
        class="searchBox"
      >
        <el-col :sm="19" :lg="21" class="searchRow" style="padding-left: 0">
          <el-input
            class="input-with-select search"
            type="text"
            v-model.trim="filter.searchKey"
            maxlength="50"
            placeholder="请输入项目名称 / 候选人姓名 / 喜报创建者，支持模糊搜索"
            style="width: 100%"
          >
            <el-button type="primary" slot="append" @click="loadlist()"
              >搜 索</el-button
            >
          </el-input>
        </el-col>
      </el-row>
      <div class="box-title" style="padding-bottom: 0">
        <el-col>
          <el-pagination
            :hide-on-single-page="filter.page.total > 10 ? false : true"
            v-if="filter.page.total > 0"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="filter.page.current"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="filter.page.size"
            layout="total,prev, pager, next,sizes"
            :total="filter.page.total"
            style="padding-right: 0px"
          ></el-pagination>
        </el-col>
        <el-col :span="24">
          <el-table
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="ListData"
            class="tabBorder custor"
            v-loading="listLoading"
            style="margin-bottom:20px"
          >
            <el-table-column label="候选人" width="120">
              <template #default="scope">
                <span class="tabHref" @click="showDrawer(scope.row.resumeId)">{{
                  scope.row.resumeName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="客户名称">
              <template #default="scope">
                <span
                  class="tabHref"
                  @click="toCustomer(scope.row.companyId)"
                  >{{ scope.row.companyName }}</span
                >
              </template></el-table-column
            >
            <el-table-column label="项目名称" show-overflow-tooltip>
              <template #default="scope">
                <router-link
                  class="tabHref"
                  target="_blank"
                  :to="{
                    path: '/project/details',
                    query: { id: scope.row.projectId },
                  }"
                  >{{ scope.row.projectName }}
                </router-link>
              </template></el-table-column
            >
            <el-table-column
              prop="offerMoney"
              label="服务费/offer业绩"
            ></el-table-column>
            <el-table-column
              prop="creatorName"
              label="喜报创建者"
            ></el-table-column>
            <el-table-column
              prop="createdTime"
              label="喜报创建时间"
            ></el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <span class="tabHref" @click="showFinancialTask(scope.row)"
                  >审核</span
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :hide-on-single-page="filter.page.total > 10 ? false : true"
            v-if="filter.page.total > 0"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="filter.page.current"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="filter.page.size"
            layout="total,prev, pager, next,sizes"
            :total="filter.page.total"
            style="padding-right: 0px"
          ></el-pagination>
        </el-col>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>
        人才
      </div>
      <div class="drawerCon">
        <resumeDetails
          v-if="drawerVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
    <!-- 审核 -->
    <el-drawer
      :visible.sync="isfinancialTask"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose1"
    >
      <div slot="title" class="drawerTitle">
        <div><i class="drawIcon iconfont icon-pl_xb"></i>喜报审核</div>
      </div>
      <!-- 发喜报-->
      <div class="drawerCon">
        <happyNews
          v-if="isfinancialTask"
          ref="saveHappyNews"
          inpath="happyNewsApproval"
          :projectResumeId="projectResumeId"
          @changeSaveLoading="changeSaveLoading"
          @loadList="loadlist"
          @closeThis='handleClose1()'
        ></happyNews>
        <div class="drawerCon_footer fixedFoot">
          <el-button
            type="default"
            plain
            size="mini"
            @click="rejectedHappyNews()"
            >驳回</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="saveInfos"
             style="margin-right:15px"
            >确认并提交</el-button
          >
        </div>
      </div>
    </el-drawer>
      <el-drawer
      :visible.sync="customerDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>客户
      </div>
      <div class="drawerCon">
        <customer
          :customId="customId"
          :activeToInfo="activedToInfo"
        ></customer>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import {
  offerGoodnewsApprovalPageList,
  offerGoodnewsApprovalReject,
} from "@/api/api";
import resumeDetails from "../../components/resumeDetail";
import happyNews from "../../components/happyNews";
import customer from "../../components/customer/customerInfo";
export default {
  components: { resumeDetails, happyNews,customer },
  data() {
    return {
      drawerVisible: false,
      resumeId: null,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      filter: {
        searchKey: "",
        page: {
          current: 1,
          size: 10,
          total: 0,
        },
      },
      ListData: [],
      listLoading: false,
      projectResumeId: null,
      saveloading: false,
      isfinancialTask: false,
      activedToInfo: "1",
      customerDrawerVisible: false,
      customId: "",
    };
  },
  created() {
    this.loadlist();
  },
  methods: {
    //关闭抽屉
    handleClose() {
      this.drawerVisible = false;
      this.customerDrawerVisible = false;
    },
    //弹出抽屉
    showDrawer(id) {
      this.drawerVisible = true;
      this.resumeId = id;
    },
    handleCurrentChange1(val) {
      this.page.current = val;
      this.loadlist();
    },
    handleSizeChange1(val) {
      //pageSize 改变时会触发
      this.page.size = val;
      this.page.current = 1;
      this.loadlist();
    },
    loadlist() {
      window.scrollTo(0, 0);
      this.isfinancialTask = false;
      //回款业绩详情
      offerGoodnewsApprovalPageList(this.filter).then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
        }
      });
    },
    //客户详情
    toCustomer(comId) {
       this.customerDrawerVisible = true;
      this.customId = comId;
    },
    //审核
    showFinancialTask(row) {
      this.projectResumeId = row.id;
      this.isfinancialTask = true;
    },
    //喜报弹窗关闭
    handleClose1() {
      this.isfinancialTask = false;
    },
    //驳回
    rejectedHappyNews() {
      offerGoodnewsApprovalReject(this.projectResumeId).then((res) => {
        if (res.success) {
          this.$message.success("驳回成功");
          this.filter.page.current = 1;
          this.loadlist();
          this.isfinancialTask = false;
        }
      });
    },
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    saveInfos() {
      this.saveloading = true;
      this.$refs.saveHappyNews
        .saveHappyNewsApproval()
        .then(() => {
          this.saveloading = false;
        })
        .catch(() => {
          this.saveloading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input-group__prepend) {
    border-left: 1px solid #e60012;
    border-top: 1px solid #e60012;
    border-bottom: 1px solid #e60012;
    line-height: 33px;
    background-color: white;
  }
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
    border-right: none;
    // border-left: none;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
</style>